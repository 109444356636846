import { Outlet } from 'react-router-dom'
const Layout = () => {
    return (
        <>
            <main className='container'>
                <Outlet />
            </main>
        </>
    )
}
export { Layout }