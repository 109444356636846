import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
let st;
class Sensors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sensorMsg: '{"null": 0}',
            sty: false
        };
    }
    render() {
        console.log(this.props.value);
        return (
            <div className={this.props.value ? 'blink m-3' : 'Sensor m-3'}>
                <Card style={{ width: '10rem' }}>
                    <Card.Body>
                        <Card.Title>{this.props.name}</Card.Title>
                        <Card.Text>
                            {this.props.unit}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <style jsx>{
                    `.Sensor {
                        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
                        transition: 0.3s;
                    }
                    .Sensor:hover {
                        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
                    }`
                }
                </style>
            </div>
        )
    }
}

export { Sensors };