import React from 'react';
import { Line } from '@ant-design/plots';

const Chart = ({ data, pridel, text, rev }) => {
  const config = {
    data,
    padding: 'auto',
    yField: 'temperature',
    xField: 'time1',
    annotations: [
      {
        type: 'regionFilter',
        start: rev ? ['min', 'max'] : ['max', 'min'],
        end: rev ? ['max', pridel] : ['min', pridel],
        // start: ['max', 'min'],
        // end: ['min', pridel],
        color: '#F4664A',
      },
      {
        type: 'text',
        position: ['min', pridel],
        content: text,
        offsetY: -4,
        style: {
          textBaseline: 'bottom',
        },
      },
      {
        type: 'line',
        start: ["min", pridel],
        end: ['max', pridel],
        style: {
          stroke: '#F4664A',
          lineDash: [20, 20],
        },
      },
    ],
  };
  return <Line {...config} />;
};
export { Chart };