import { Col, Container, Row } from "react-bootstrap"
import { Sensors } from "../components/Sensors"
import { Chart } from "../components/Chart"
import Table from 'react-bootstrap/Table';
import ReactExport from "react-export-excel";
import Paper from '@mui/material/Paper';
import { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let timer
export const APIJSONGET = async (url = '') => {
    let a = await fetch(`${url}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response.json().then(data => ({ status: response.status, data: data }));
            } else {
                return response.text().then(data => ({ status: response.status, data: data }));
            }
        })
        .then(result => {
            return result
        })
    return a;
}
const HomePage = () => {
    const tableRef = useRef(null);
    const [datas, setDates] = useState([{}])
    const [temperatures, setTemperatures] = useState([{}])
    const [humiditys, setHumiditys] = useState([{}])
    const [zaryad, setZaryad] = useState([{}])
    const [t, sett] = useState(0);
    const [h, seth] = useState(0);
    const [z, setz] = useState(0);

    useEffect(() => {
        clearInterval(timer)
        timer = setInterval(async () => {
            if (window.location.pathname !== '/home') {
                clearInterval(timer)
            }
            let json = await APIJSONGET('/api/web/date');
            let arrTemp = []
            let arrHum = []
            let arrZar = []
            json.data.map((items) => {
                const { t, servertime, id, h, z } = items;
                arrTemp.push({ temperature: parseInt(t), time1: servertime, id });
                arrHum.push({ temperature: parseInt(h), time1: servertime, id });
                arrZar.push({ temperature: parseInt(z), time1: servertime, id });
                return;
            });
            setTemperatures(arrTemp)
            setHumiditys(arrHum)
            setZaryad(arrZar)
            setDates(json.data);
            sett(parseInt(json.data[0].t));
            seth(parseInt(json.data[0].h));
            setz(parseInt(json.data[0].z));

        }, 5000);
    }, [])
    return (
        <Paper elevation={9}>
            <div style={{ padding: "30px" }}>
                <Container className="p-4">
                    <Row className="p-3 justify-content-md-center">
                        <Col md="auto">
                            <Sensors name="Temperature" unit={t + " °C"} value={t <= 35 ? false : true} />
                        </Col>
                        <Col md="auto">
                            <Sensors name="Humidity" unit={h + " %"} value={h <= 16 ? false : true} />
                        </Col>
                        <Col md="auto">
                            <Sensors name="Zaryad" unit={z + " %"} value={z >= 19 ? false : true} />
                        </Col>
                    </Row>
                </Container>
                <div style={{ float: 'right', padding: '20px' }}>
                    <ExcelFile element={<Button variant="outlined">Download Data</Button>}>
                        <ExcelSheet data={datas} name="Natija">
                            <ExcelColumn label="№" value="id" />
                            <ExcelColumn label="Baza" value="baza" />
                            <ExcelColumn label="Bunt" value="bunt" />
                            <ExcelColumn label="Joy" value="joy" />
                            <ExcelColumn label="Harorat" value="t" />
                            <ExcelColumn label="Namlik" value="h" />
                            <ExcelColumn label="Zaryad" value="z" />
                            <ExcelColumn label="Vaqt" value="time1" />
                            <ExcelColumn label="Server vaqt" value="servertime" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
                <div className="Sensor2">
                    <Table striped bordered hover ref={tableRef}>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Baza</th>
                                <th>Bunt</th>
                                <th>Joy</th>
                                <th>Harorat</th>
                                <th>Namlik</th>
                                <th>Zaryad</th>
                                <th>Vaqt</th>
                                <th>Server vaqt</th>

                            </tr>
                        </thead>
                        <tbody>
                            {datas.map((items, index) => {
                                if (index < 10) return (
                                    <tr>
                                        <td>{items.id}</td>
                                        <td>{items.baza}</td>
                                        <td>{items.bunt}</td>
                                        <td>{items.joy}</td>
                                        <td>{items.t}</td>
                                        <td>{items.h}</td>
                                        <td>{items.z}</td>
                                        <td>{items.time1}</td>
                                        <td>{items.servertime}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <p class="text-center p-3" style={{ fontSize: "30px" }}>Harorat</p>
                <div className="Sensor2">
                    <Chart data={temperatures} pridel={'35'} text={'Harorat chegarasi'} rev={true} />
                </div>
                <p class="text-center p-3" style={{ fontSize: "30px" }}>Namlik</p>
                <div className="Sensor2">
                    <Chart data={humiditys} pridel={'16'} text={'Namlik chegarasi'} rev={true} />
                </div>
                <p class="text-center p-3" style={{ fontSize: "30px" }}>Zaryad</p>
                <div className="Sensor2">
                    <Chart data={zaryad} pridel={'20'} text={'Zaryad chegarasi'} rev={false} />
                </div>
            </div>
        </Paper>
    )
}
export { HomePage }