import { Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/HomePage'
// import { AboutPage } from './pages/AboutPage'
import { BlogPage } from './pages/BlogPage'
import { NotFoundPage } from './pages/NotFoungPage'

import { Layout } from './components/Layout'
import { LoginPage } from './pages/LoginPage'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<LoginPage />} />
          <Route path='home' element={<HomePage />} />
          <Route path='posts' element={<BlogPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
