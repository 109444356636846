import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const onFSubmit = (event) => {
    event.preventDefault();
    if (login === 'admin@admin.uz'&& password === 'admin') {
      navigate('/home');
    }
  }
  return (
    <div className="row">
      <div className="col-md-12 col-md-offset-12">
        <Form onSubmit={onFSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Login</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={(e) => { setLogin(e.target.value) }} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Remember" />
          </Form.Group>
          <Button variant="primary" type="submit">
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
}

export { LoginPage };